import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../assets/style/Header.css";
import CTAButton from "./CTAButton";

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [click, setClick] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      setScrolled(scrollPosition > 0);
    }

    function handleClickOutside(event) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setClick(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function handleBtnClick(event) {
    // Stop the event propagation to prevent immediate toggling
    event.stopPropagation();
    setClick(!click);
  }

  const closeNavbar = () => {
    // Close the navbar when a link is clicked
    setClick(false);
  };

  return (
    <header id="header" className={`${scrolled ? "scrolled" : ""}`} dir="rtl">
      <div id="logo-container">
        <img src="../adaptek.ico" alt="AdapTek" className="logo" />
      </div>

      <button
        id="mobile-nav-toggle"
        aria-controls="primary-nav"
        aria-expanded="false"
        onClick={handleBtnClick}
        className={`${click ? "btn-close" : "btn-open"}`}
      ></button>
      <nav id="menu-nav" ref={navRef} dir="rtl">
        <ul id="primary-nav" className={`${click ? "navShow" : "navHide"}`}>
          <NavLink to="/" className="link" onClick={closeNavbar}>
            <li>الرئيسية</li>
          </NavLink>
          <NavLink to="/about" className="link" onClick={closeNavbar}>
            <li>من نحن</li>
          </NavLink>
          <NavLink to="/contact" className="link" onClick={closeNavbar}>
            <li>تواصل معنا</li>
          </NavLink>
        </ul>
      </nav>
      <CTAButton
        title="ابدأ الأن"
        padding="15px"
        paddingTopBottom="3px"
        btnId="header-cta-button"
      />
    </header>
  );
}
