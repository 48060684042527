import { useEffect } from "react";
import "../assets/style/aboutUsPage.css";
import CTAButton from "../components/CTAButton";
import storyImg from "../assets/img/robot-with-phone.png";
import teamImg from "../assets/img/hosting.png";
import gearImg from "../assets/img/gear.png";
import workerRobotoImg from "../assets/img/worker-tobot.png";
import writeRobot from "../assets/img/write-robot.png";

export default function AboutUSPage() {
  useEffect(() => {
    document.title = "About | AdapTek";
  }, []);
  return (
    <div id="about-us-page">
      <AboutHeroSection />
      <OurStorySection />
      <MissionSection />
      <TeamSection />
      <CtaSection />
    </div>
  );
}

function AboutHeroSection() {
  return (
    <div id="about-hero-section">
      <div id="inner-hero">
        <h3 className="section-title">من نحن</h3>
        <h1 className="section-content-title">
          نقوم في أدابتيك بتطوير وتحسين خدماتك
        </h1>
        <p>
          نحن نسعى لتجديد مفهوم خدمة روبوت المحادثة واضافة قوة الاتمتة وعمليتها
          على أرض الواقع وتحويل العمليات الروتينية الى أعمال تتم عن طريق النظام
          الحاسوبي
        </p>
        <CTAButton
          title="ابدأ اول خطوة الأن"
          padding="20px"
          fontSize="17px"
          paddingTopBottom="12px"
        />
        <img src={gearImg} loading="lazy" alt="gear" id="gear-img" />
        <img
          src={workerRobotoImg}
          loading="lazy"
          alt="worker robot"
          id="worker-robot-img"
        />
      </div>
    </div>
  );
}

function OurStorySection() {
  return (
    <div id="our-story">
      <div id="inner-story" dir="rtl">
        <div id="text-story">
          <h3 className="section-title">قصتنا</h3>
          <h1 className="section-content-title">
            مواكبة التطور وبداية عصر الذكاء الاصطناعي
          </h1>
          <p>
            مع تطور قدرات الذكاء الاصطناعي أصبح يمكننا الاعتماد على هذا الذكاء
            لتطوير الادوات والطرق المستخدمة, ومع ادابتيك نقدم لك حلول الاتمتة
            والذكاء الاصطناعي باحدث الطرق والادوات.
          </p>
        </div>
        <div id="img-story-container">
          <img src={storyImg} id="story-img" alt="story img" />
        </div>
      </div>
    </div>
  );
}

function MissionSection() {
  return (
    <div id="mission-section">
      <h3 className="section-title">مهمتنا</h3>
      <h1 className="section-content-title">أتمتة العمليات الروتينة</h1>
      <p>
        في أدابتيك، نحن في مهمة تتمثل في إحداث ثورة في المشهد الرقمي الخاص بك.
        متخصصون في إنشاء روبوتات الدردشة المخصصة، وتصميم قواعد البيانات القوية،
        والأتمتة الإستراتيجية، ونحن نمكن الشركات من رفع مستوى تواجدها عبر
        الإنترنت. هدفنا هو تعزيز الكفاءة وتعزيز مشاركة العملاء ودفع عملك نحو
        مستقبل رقمي سلس ومبتكر
      </p>
      <img src={writeRobot} alt="robot writing" id="write-robot-img" />
    </div>
  );
}

function TeamSection() {
  return (
    <div id="team-section">
      <div id="inner-team-section" dir="rtl">
        <div id="team-img-container">
          <img src={teamImg} id="team-img" alt="our team" />
        </div>
        <div id="text-team-container">
          <h3 className="section-title">فريقنا</h3>
          <h1 className="section-content-title">إلتق بفريقنا</h1>
          <p>
            فريقنا هو القلب النابض للابتكار, بدءًا من مطوري برامج الدردشة إلى
            مهندسي قواعد البيانات وخبراء الأتمتة، تتقارب مواهبنا المتنوعة لصياغة
            الحلول التي تدفع الشركات إلى الأمام, يوحدنا شغف التميز والإبداع
            والتعاون، ويساهم كل عضو في الفريق بمجموعة مهارات فريدة لتحقيق نتائج
            استثنائية. اكتشف الوجوه التي تقودك إلى آفاق جديدة
          </p>
        </div>
      </div>
    </div>
  );
}

function CtaSection() {
  return (
    <div id="cta-section">
      <h1 className="section-content-title">
        ارفع مستوى عملك باستخدام حلول أدابتيك
      </h1>
      <CTAButton btnId="cta-btn-1" title="أحصل على إستشارتك المجانية الأن" />
    </div>
  );
}
