import { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "../assets/style/ctaPage.css";

export default function CtaPage() {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [minDate, setMinDate] = useState("");
  const [failMsg, setFailMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm("service_kuirqpq", "template_hez108a", form.current, {
        publicKey: "D-v55J0o_1K0effwE",
      })
      .then(
        () => {
          setSuccessMsg(true);
          setFailMsg(false);
          setInputEmpty();
        },
        (error) => {
          console.log("FAILED...", error.text);
          setFailMsg(true);
          setSuccessMsg(false);
        }
      );
  }

  function hideMessages() {
    setSuccessMsg(false);
    setFailMsg(false);
  }

  function setInputEmpty() {
    setNameInput("");
    setEmailInput("");
    setDate("");
    setTime("");
  }

  function timeValidate(e) {
    const selectedTime = new Date(`2000-01-01T${time}`);
    const minTime = new Date(`2000-01-01T09:00`);
    const maxTime = new Date(`2000-01-01T17:00`);

    if (selectedTime < minTime || selectedTime > maxTime) {
      alert("Please select a time between 09:00 AM and 05:00 PM.");
      setTime("");
    }
  }

  useEffect(() => {
    document.title = "Set a meeting | AdapTek";

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const formattedTomorrow = tomorrow.toISOString().split("T")[0];
    setMinDate(formattedTomorrow);

    document.addEventListener("click", hideMessages);
    return () => {
      document.removeEventListener("click", hideMessages);
    };
  }, []);

  return (
    <div id="cta-page">
      <h1 id="cta-title">أبدا رحلة التطوير الأن</h1>
      <div id="form-container">
        <form id="set-meeting-form" ref={form} onSubmit={sendEmail}>
          <h2>حدد موعد للمقابله</h2>
          <input
            type="text"
            placeholder="الإسم"
            name="user_name"
            value={nameInput}
            onChange={(event) => {
              setNameInput(event.target.value);
            }}
            required
          />
          <input
            type="email"
            placeholder="الإيميل"
            name="user-email"
            value={emailInput}
            onChange={(event) => {
              setEmailInput(event.target.value);
            }}
            required
          />
          <input
            type="date"
            id="dateInput"
            name="meet-day"
            min={minDate}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          ></input>
          <input
            type="time"
            id="timeInput"
            name="meet-time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            onBlur={timeValidate}
          ></input>
          <button type="submit">إحجز موعدك</button>
        </form>
      </div>
      {successMsg && (
        <div className="blurry-container">
          <div
            id="success-message-container"
            className="message-container"
            dir="rtl"
          >
            <i className="fas fa-check-circle"></i>
            <p>لقد تم حجز الموعد بنجاح</p>
          </div>
        </div>
      )}
      {failMsg && (
        <div className="blurry-container">
          <div
            id="fail-message-container"
            className="message-container"
            dir="rtl"
          >
            <i className="fas fa-times-circle"></i>
            <p>حدث خطأ! يمكنك مراسلتنا على الإيميل info@adapt-teknologe.com</p>
          </div>
        </div>
      )}
    </div>
  );
}
