import "../assets/style/ctaBtn.css";
import { useNavigate } from "react-router-dom";

export default function CTAButton(props) {
  const navigate = useNavigate();
  const { title, padding, paddingTopBottom, btnId, fontSize } = props;
  const buttonStyle = padding
    ? {
        paddingLeft: padding,
        paddingRight: padding,
        paddingTop: paddingTopBottom,
        paddingBottom: paddingTopBottom,
        fontSize: fontSize,
      }
    : {};
  return (
    <button
      className="CTA-btn"
      id={btnId}
      style={buttonStyle}
      onClick={() => {
        navigate("/pick-date");
      }}
    >
      {title}
    </button>
  );
}
