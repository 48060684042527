import { Link } from "react-router-dom";
import "../assets/style/footer.css";
import logo from "../assets/img/adaptek.ico";

export default function Footer() {
  return (
    <div>
      <footer id="footer" dir="rtl">
        <div id="footer-logo-section">
          <div id="footer-logo-container">
            <img src={logo} alt="AdapTek" id="footer-logo" />
          </div>
        </div>
        <div id="footer-content-container">
          <div id="footer-link-section">
            <Link to="/" className="footer-link">
              الرئيسية
            </Link>
            <Link to="/about" className="footer-link">
              من نحن
            </Link>
            <Link to="/contact" className="footer-link">
              تواصل معنا
            </Link>
          </div>
          <div id="footer-socail-media-secation">
            <a
              href="https://www.instagram.com/adaptek_"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram icon"></i>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61556725195560"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook icon"></i>
            </a>
            <a
              href="https://wa.me/905540209188"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp icon"></i>
            </a>
            <a
              href="https://www.tiktok.com/@adaptek?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok icon"></i>
            </a>
          </div>
        </div>
      </footer>
      <div id="riguts">
        <p>© 2024 AdapTek. All rights reserved.</p>
      </div>
    </div>
  );
}
