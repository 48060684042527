import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutUSPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import CtaPage from "./pages/CtaPage";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga"; //google analytics

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-42XEMQS010");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<Header />} />
          <Route path="/pick-date" element={<></>} />
        </Routes>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUSPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/pick-date" element={<CtaPage />} />
        </Routes>
        <Routes>
          <Route path="*" element={<Footer />} />
          <Route path="/pick-date" element={<></>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
