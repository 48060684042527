import { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "../assets/style/contactUsPage.css";

export default function ContactUsPage() {
  const form = useRef();
  const [successMsg, setSuccessMsg] = useState(false);
  const [failMsg, setFailMsg] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [messageInput, setMessageInput] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_kuirqpq", "template_v3qrbxk", form.current, {
        publicKey: "D-v55J0o_1K0effwE",
      })
      .then(
        () => {
          setFailMsg(false);
          setSuccessMsg(true);
          setInputEmpty();
        },
        (error) => {
          setFailMsg(true);
          setSuccessMsg(false);
          console.log("FAILED...", error.text);
        }
      );
  };

  function hideMessages() {
    setSuccessMsg(false);
    setFailMsg(false);
  }

  function setInputEmpty() {
    setNameInput("");
    setEmailInput("");
    setMessageInput("");
  }

  useEffect(() => {
    document.title = "Contact | AdapTek";

    document.addEventListener("click", hideMessages);
    return () => {
      document.removeEventListener("click", hideMessages);
    };
  }, []);

  return (
    <div id="contact-us-page" dir="rtl">
      <div id="contact-box-container">
        <div id="inner-contact">
          <h1>تواصل معنا</h1>
          <p>
            قدم اي استفسارات لنا في اي وقت, وسنقوم بالرد عليك في اقرب وقت ممكن
          </p>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              className="input"
              name="user_name"
              placeholder="الإسم"
              value={nameInput}
              onChange={(event) => {
                setNameInput(event.target.value);
              }}
              required
            />
            <input
              type="email"
              className="input"
              name="user-email"
              placeholder="الإيميل"
              value={emailInput}
              onChange={(event) => {
                setEmailInput(event.target.value);
              }}
              required
            />
            <input
              type="text"
              className="input"
              name="message"
              placeholder="الرسالة"
              value={messageInput}
              onChange={(event) => {
                setMessageInput(event.target.value);
              }}
              required
            />
            <button id="send-btn" type="submit">
              إرسال
            </button>
          </form>
        </div>
      </div>
      <div id="contact-info" dir="ltr">
        <div className="contact-icon-container">
          <a
            href="mailto:info@adapt-teknologe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-regular fa-envelope contact-icon"></i>
          </a>
        </div>
        <div className="contact-icon-container">
          <a
            href="https://wa.me/905540209188"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp contact-icon"></i>
          </a>
        </div>
        <div className="contact-icon-container">
          <a
            href="https://www.instagram.com/adaptek_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram contact-icon"></i>
          </a>
        </div>
      </div>
      {successMsg && (
        <div className="blurry-container">
          <div id="success-message-container" className="message-container">
            <i className="fas fa-check-circle"></i>
            <p>تم الإرسال بنجاح</p>
          </div>
        </div>
      )}
      {failMsg && (
        <div className="blurry-container">
          <div id="fail-message-container" className="message-container">
            <i className="fas fa-times-circle"></i>
            <p>
              حدث خطأ!! يمكنك التواصل معنا عبر الإيميل info@adapt-teknologe.com
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
