import { useEffect } from "react";
import ParticlesBackground from "../components/ParticleBackground";
import "../assets/style/HomePage.css";
import CTAButton from "../components/CTAButton";
import chatRobot from "../assets/img/robot-home.png";
import adsVideo from "../assets/video/adsVideo.mp4";
import chatBot from "../assets/img/chatbot.png";
import database from "../assets/img/database.png";
import robotic from "../assets/img/robotic.png";
import FAQItem from "../components/FAQItem";

export default function HomePage() {
  useEffect(() => {
    document.title = "AdapTek";
  }, []);
  return (
    <div className="page-content">
      <HeroSection />
      <VideoSection />
      <FeaturesSection />
      <CtaSeaction />
      <FaqSection />
    </div>
  );
}

function HeroSection() {
  return (
    <div id="hero-section" dir="rtl">
      <div id="tsparticles-container">
        <ParticlesBackground id="tsparticles" />
      </div>
      <div className="half-page-continer">
        <div id="main-inner-container">
          <h1 id="main-title">
            <span className="color1">أدا</span>
            <span className="mix-color">بت</span>يك: شريكك الوثيق في عصر التقنية
          </h1>
          <p>
            قم بتطوير أعمالك، وإعادة تشكيل تصورات العملاء، والارتقاء بعلامتك
            التجارية من خلال حلول روبوت المحادثة المتخصصة لدينا والمصممة خصيصًا
            لتناسب مجال عملك
          </p>
          <CTAButton title="أحصل على إستشارة مجانية الأن" id="hero-cta-btn" />
        </div>
      </div>
      <div className="half-page-continer robot-img-container">
        <img src={chatRobot} alt="chat robot" id="robot-img" />
      </div>
    </div>
  );
}

function VideoSection() {
  return (
    <div id="video-section">
      <div id="text-container">
        <div id="inner-text-container">
          <h1>قم بتطوير عملك باستخدام أدابتيك</h1>
          <p>
            في أدابتيك، نحن ملتزمون بإحداث ثورة في طريقة عمل الشركات في العصر
            الرقمي. ارفع مستوى مشاركة عملائك من خلال تكامل برنامج الدردشة الآلي
            المتطور لدينا، مما يوفر تفاعلات مخصصة وفعالة. تمتد خبرتنا إلى ما هو
            أبعد من روبوتات الدردشة - فنحن متخصصون في إنشاء قواعد بيانات مصممة
            خصيصًا لتعزيز قدرات إدارة البيانات لديك. بالإضافة إلى ذلك، يلتزم
            فريقنا بإرشادك خلال رحلة الأتمتة، وتبسيط عمليات عملك لزيادة الكفاءة
            والإنتاجية. احتضن الابتكار مع أدابتيك وابدأ في تجربة تحويلية تدفع
            عملك إلى مستقبل من العمليات السلسة وتعزيز رضا العملاء
          </p>
        </div>
      </div>
      <div id="video-container">
        <video id="video" src={adsVideo} controls />
      </div>
    </div>
  );
}

function FeaturesSection() {
  useEffect(() => {
    function reveal() {
      const reveals = document.querySelectorAll(".feature");

      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const revelTop = reveals[i].getBoundingClientRect().top;
        const revelPoint = 120;

        if (revelTop < windowHeight - revelPoint) {
          reveals[i].classList.add("feature-active");
        }
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", reveal);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

  return (
    <div id="features-section">
      <h1>ما الذي نقوم بعمله؟</h1>
      <div id="features-container" dir="rtl">
        <div className="feature feature-hidden">
          <div className="feature-head">
            <img src={chatBot} alt="chatbot" className="icon-img" />
            <h4>روبوت المحادثة</h4>
          </div>
          <p>
            نقوم بإنشاء روبوتات دردشة مخصصة لموقع الويب الخاص بك ووسائل التواصل
            الاجتماعي. قم برفع مستوى مشاركة العملاء وتبسيط العمليات من خلال
            تصميمات الخبراء لدينا
          </p>
        </div>
        <div className="feature feature-hidden">
          <div className="feature-head">
            <img src={database} alt="database" className="icon-img" />
            <h4>تصميم قاعدة البيانات</h4>
          </div>
          <p>
            نقوم بتحسين وتبسيط العمليات من خلال تصميم وتطوير قاعدة بيانات سريعة
            الاستجابة وفعالة لموقعك على الويب
          </p>
        </div>
        <div className="feature feature-hidden">
          <div className="feature-head">
            <img src={robotic} alt="robotic" className="icon-img" />
            <h4>أتمتة الأنظمة</h4>
          </div>
          <p>
            في أدابتيك نلتزم بتطوير وتحديث الأنظمة لتواكب التطور المستمر للأعمال
            , نقوم باتمتة العمليات الروتينية في الأنظمة ومراقبة أداء النظام
          </p>
        </div>
      </div>
    </div>
  );
}

function CtaSeaction() {
  return (
    <div id="cta-seaction">
      <h1>أبدا نقلة نوعية في عملك الأن</h1>
      <CTAButton
        id="cta-section-cta-btn"
        title="تواصل معنا الأن"
        padding="8vh"
        fontSize="15px"
      />
    </div>
  );
}

function FaqSection() {
  return (
    <div id="faq-section">
      <h1>الأسئلة الشائعة</h1>
      <div id="faq-container" dir="rtl">
        <FAQItem
          question="كيف يمكن لروبوت الدردشة أن يفيد أعمالي؟"
          answer="            يمكن لروبوت الدردشة تحسين مشاركة العملاء، وأتمتة المهام الروتينية،
            وتوفير استجابات فورية للاستفسارات، وتحسين الكفاءة العامة. إنها أداة
            قوية لزيادة رضا المستخدمين وتوفير الوقت."
        />
        <FAQItem
          question="ما هي الصناعات التي يمكن أن تستفيد من خدمات chatbot الخاصة بك؟"
          answer="            تتميز خدمات chatbot لدينا بأنها متعددة الاستخدامات ويمكن تخصيصها
            لمختلف الصناعات، بما في ذلك التجارة الإلكترونية والرعاية الصحية
            والتمويل ودعم العملاء والمزيد. نحن نصمم حلولاً لتلبية الاحتياجات
            الفريدة لكل شركة."
        />
        <FAQItem
          question="ما هي الميزات التي يقدمها برنامج chatbot الخاص بك؟"
          answer="            يأتي برنامج الدردشة الآلي الخاص بنا مزودًا بمجموعة من الميزات، بما
            في ذلك معالجة اللغة الطبيعية وإدارة تدفق المحادثة والتكامل مع
            الأنظمة الخارجية والدعم متعدد القنوات والتحليلات وإعداد التقارير.
            تضمن هذه الميزات تجربة مستخدم سلسة وفعالة."
        />
        <FAQItem
          question="كيف يتكامل برنامج chatbot مع الأنظمة والعمليات الحالية؟"
          answer="            تم تصميم برنامج الدردشة الآلي الخاص بنا للتكامل بسلاسة مع الأنظمة
            والعمليات الحالية لديك. سواء كان الأمر يتعلق بتكامل CRM، أو الاتصال
            بقاعدة البيانات، أو تطبيقات الطرف الثالث الأخرى، فإننا نعمل على
            تبسيط التفاعلات وتدفق البيانات، مما يزيد من فوائد الأتمتة."
        />
        <FAQItem
          question="هل يستطيع الشات بوت التعامل مع المحادثات والمهام المعقدة؟"
          answer="
          نعم، الشات بوت الخاص بنا قادر على التعامل مع المحادثات والمهام
          المعقدة. ومن خلال المعالجة المتقدمة للغة الطبيعية والتعلم الآلي،
          يمكنها فهم نوايا المستخدم وتقديم الاستجابات ذات الصلة وتنفيذ مسارات
          عمل متطورة لتلبية متطلبات العمل المحددة."
        />
        <FAQItem
          question="ما هو مستوى التخصيص المتاح لروبوت الدردشة؟"
          answer="
          نحن ندرك أن كل عمل تجاري فريد من نوعه. تتميز حلول Chatbot الخاصة بنا
          بأنها قابلة للتخصيص بدرجة كبيرة، مما يسمح لك بتخصيص تدفق المحادثة
          والتصميم والعلامة التجارية والوظائف لتتوافق مع هوية علامتك التجارية
          واحتياجات العمل المحددة."
        />
      </div>
    </div>
  );
}
