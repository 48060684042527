import React, { useState } from "react";
import "../assets/style/HomePage.css";

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
    } else {
    }
  };

  return (
    <div className={`faq ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <h4>{question}</h4>
      <p className={`answer ${isOpen ? "visible" : ""}`}>{answer}</p>
    </div>
  );
}

export default FAQItem;
